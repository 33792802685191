import React from "react"
import Layout from "../components/layouts"
import { siteMetadata } from '../../gatsby-config'
import Meta from '../components/meta'

export default (props) => {
  const { location } = props
  
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="404" />
      <div className="container-grey min-vh-100 p-5 text-center">
        <h1>404</h1>
        <h2>Dokument nicht gefunden</h2>
        <p><a href="/">Zurück zur Startseite</a></p>
      </div>
    </Layout>
  )
}